// import {httpGet} from 'api/api'
// import address from '../../api/apiAdd.js'

import { getJsapiSignature } from '@/api/weixin'
import wx from 'weixin-js-sdk'
// import {commFunc} from "./util";
 
// 分享内容填充和调用分享方法
export function share(param) {
	var title = param.title
	var imgUrl = param.imgUrl
	var description =param.description
	var url=param.url
	var successMethod = param.successMethod
	var tempParam = {
		'title': title,
		'desc': description,
		'link': url,
		'imgUrl': imgUrl,
		success: successMethod
	};
	//分享给朋友
	wx.onMenuShareAppMessage(tempParam);
	//分享给朋友圈
	wx.onMenuShareTimeline(tempParam);
}
 
// 页面调用分享功能的方法
export function init(param) {
  if (!param) {
    return
  }
  sign(param)
}
 
// 向后端请求权限验证配置的基本数据
function sign(param) {
	var pageUrl = window.location.href
	// httpGet(address.weChatSignCheck + '?pageUrl=' +pageUrl).then((res) => {
	// //param.downloadUrl = res.data.downloadUrl
  
	// })
	let queryParams={}
	queryParams.url=pageUrl
	getJsapiSignature(queryParams).then(res => {
		initAPIs(res.data,param)
	})
  
}
// 权限验证数据填充
function initAPIs(data,param) {
	wx.config({
		debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
		appId: data.appId,
		timestamp: data.timestamp,
		nonceStr: data.nonceStr,
		signature: data.signature,
		jsApiList: param.APIs
	})
  // 验证成功后执行的方法
	wx.ready(function () {
		// if (commFunc.isNotEmpty(param.callback)) {
		// 	console.log(param.callback)
			share(param.callback)
		// }
	})
  // 判断客户端是否支持指定js的接口
	wx.checkJsApi({
		jsApiList:param.APIs,
		success: function (res) {
			return res
		}
	})
}
