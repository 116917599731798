import axios from 'axios'
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
	timeout: 5000 // request timeout
})
service.interceptors.request.use(
	config => {
	return config
	},
	error => {
		return Promise.reject(error)
	}
)
// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		const status=response.status
		// if the custom code is not 20000, it is judged as an error.
		if (status !== 200) {
			if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
				console.log(res)
			}
			return Promise.reject(new Error(res.message || 'Error'))
		} else {
			return res
		}
	},
	error => {
		console.log('err' + error) // for debug
		return Promise.reject(error)
	}
)
export default service
