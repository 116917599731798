import request from '@/utils/request'

export function getJsapiSignature(params) {
  return request({
    url: '/wx/jsapi/wxe5ff17e14deb4fd5/getJsapiSignature',
    method: 'post',
    params
  })
}

